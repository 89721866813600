<template>
  <div class="page">
    <div class="content-box section">
      <div class="tabs-wrap">
        <el-button class="back-button" type="text" @click="goBack"
        ><i class="el-icon-back"></i>上一页
        </el-button
        >
      </div>
      <div class="add-parking-basic">
        <el-form
            ref="model"
            :model="model"
            :rules="rules"
            class="demo-ruleForm"
            label-width="120px"
            size="small"
        >
          <easy-card title="车场信息">
            <el-row>
              <el-col :span="12">
                <el-form-item label="套餐名称" prop="leaseTitle">
                  <el-input
                      v-model="model.leaseTitle"
                      placeholder="请输入-套餐名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="选择车场" prop="parkingId">
                  <SuggestParking
                      v-if="id === ''"
                      v-model="model.parkingId"
                      class="common-input"
                  />
                  <el-input v-else
                            v-model="model.parkingName" :disabled="editDisabledInput"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="长租周期">
                  <el-select
                      v-model="model.longLeasePeriod"
                      :disabled="editDisabledInput"
                      style="width: 100%"
                  >
                    <el-option label="天" value="4"></el-option>
                    <el-option label="月" value="0"></el-option>
                    <el-option label="季度" value="1"></el-option>
                    <el-option label="半年" value="2"></el-option>
                    <el-option label="全年" value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="场内继承">
                  <el-select
                      v-model="model.parkingLotInherit"
                      disabled
                      style="width: 100%"
                  >
                    <el-option label="允许" value="0"></el-option>
                    <el-option label="禁止" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="有效时段">
              <el-select
                  v-model="model.limitTime"
                  :disabled="editDisabledInput"
              >
                <el-option label="不限(周一至周日)" value="0"></el-option>
              </el-select>
              <el-select
                  v-model="model.effectualTime"
                  :disabled="editDisabledInput"
                  @change="changeDisable"
              >
                <el-option label="全天" value="1"></el-option>
                <el-option label="分时段" value="0"></el-option>
              </el-select>
              <el-time-picker
                  v-model="model.beginTime"
                  :disabled="disabledInput"
                  format="HH:mm"
                  placeholder="开始时间"
                  value-format="HH:mm"
              >
              </el-time-picker>
              <el-time-picker
                  v-model="model.endTime"
                  :disabled="disabledInput"
                  format="HH:mm"
                  placeholder="结束时间"
                  value-format="HH:mm"
              >
              </el-time-picker>
            </el-form-item>
          </easy-card>

          <easy-card title="购买信息">
            <el-row>
              <el-col :span="12">
                <el-form-item label="支持购买方式">
                  <el-select
                      v-model="model.buyWay"
                      style="width: 100%"
                  >
                    <el-option label="开放购买" value="0"></el-option>
                    <el-option label="审核购买" value="1"></el-option>
                    <el-option label="专享购买" value="2"></el-option>
                    <el-option label="不开放购买" value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="线上展示">
                  <el-select
                      v-model="model.onlineShow"
                      style="width: 100%"
                  >
                    <el-option label="是" value="0"></el-option>
                    <el-option label="否" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                    class="title-label"
                    label="支持购买车牌"
                    prop="licensePlateColor"
                >
                  <el-select
                      v-model="model.licensePlateColor"
                      multiple
                      style="width: 100%"
                      @change="changeValue"
                  >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :disabled="item.disabled"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="套餐价格" prop="leasePrice">
                  <el-input
                      v-model="model.leasePrice"
                      placeholder="请输入-套餐价格"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="套餐说明">
              <el-input v-model="model.leaseRemark" type="textarea"></el-input>
            </el-form-item>
          </easy-card>
          <el-button
              class="xxd-button_save"
              type="primary"
              @click="save('model')"
          >保存
          </el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {addOrUpdateLeaseInfo, getLeaseInfoById} from "@/api/lease";
import SuggestParking from "@/components/SuggestParking";

export default {
  name: "addLease",
  data() {
    return {
      disabledInput: true, //默认禁用时间选择
      editDisabledInput: false, //判断是否编辑，编辑禁止修改选项
      id: "", //上个页面传过来的id
      model: {
        leaseTitle: "", //套餐名称
        parkingId: "", //车场id
        parkingName: '',
        longLeasePeriod: "0", //长租周期
        parkingLotInherit: "1", //场内继承
        limitTime: "0", //有效时段--不限
        effectualTime: "1", //有效时段  1全天  0分时段(20220805修改，枚举值传反了)
        beginTime: "00:00", //分时段  开始时间
        endTime: "23:59", //分时段  结束时间
        buyWay: "", //购买方式  0开放购买  1审核购买  2专享购买  3不开放购买
        onlineShow: "", //线上展示  0是（写死）  1否
        licensePlateColor: ["0"], //购买车牌  0通用  01黄牌  02蓝牌  03黑牌  04新能源
        leasePrice: "", // 套餐价格
        leaseRemark: "", //套餐说明
      },
      options: [
        {
          value: "0",
          label: "通用",
          disabled: false,
        },
        {
          value: "01",
          label: "黄牌",
          disabled: true,
        },
        {
          value: "02",
          label: "蓝牌",
          disabled: true,
        },
        {
          value: "03",
          label: "黑牌",
          disabled: true,
        },
        {
          value: "04",
          label: "新能源",
          disabled: true,
        },
      ],
      rules: {
        leaseTitle: [
          {required: true, message: "套餐名称不能为空", trigger: "blur"},
        ],
        parkingId: [{required: true, message: "请选择车场", trigger: "change"}],
        licensePlateColor: [
          {required: true, message: "请选择支持购买车牌", trigger: "change"},
        ],
        leasePrice: [
          {required: true, message: "套餐价格不能为空", trigger: "blur"},
        ],
      },
    };
  },
  components: {
    SuggestParking,
  },
  methods: {
    //返回长租套餐列表页面
    goBack() {
      this.$router.back();
    },
    //判断是否禁用时间选择  0全天禁用  1分时段不禁用
    changeDisable() {
      if (this.model.effectualTime === "1") {
        this.disabledInput = true;
      } else {
        this.disabledInput = false;
      }
    },
    //判断车牌为通用其他禁选
    changeValue() {
      const option = this.options;
      if (this.model.licensePlateColor[0] === "0") {
        option.map((item) => {
          if (item.value === "0") {
            item.disabled = false;
          } else {
            item.disabled = true;
          }
        });
      } else if (this.model.licensePlateColor.length === 0) {
        option.map((item) => {
          item.disabled = false;
        });
      } else {
        option.map((item) => {
          if (item.value === "0") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      }
    },
    //保存新建或编辑
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const leaseinfo = JSON.stringify({
            id: this.id,
            title: this.model.leaseTitle,
            parkingLotId: this.model.parkingId,
            priceType: this.model.longLeasePeriod,
            // parkingLotInherit : this.model.parkingLotInherit,  //场内继承不传
            // limitTime : this.model.limitTime,   /不限周一至周日不传
            timeScope: this.model.effectualTime,
            scopeStart:
                this.model.effectualTime === "1" ? "" : this.model.beginTime,
            scopeEnd:
                this.model.effectualTime === "1" ? "" : this.model.endTime,
            payType: this.model.buyWay,
            onlineShow: this.model.onlineShow,
            plate: JSON.stringify(this.model.licensePlateColor),
            price: this.model.leasePrice,
            describe: this.model.leaseRemark,
          });

          addOrUpdateLeaseInfo(leaseinfo).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message.success("保存成功！");
              this.$router.back();
            }
          });
        } else {
          return false;
        }
      });
    },
    //查询长租套餐详情
    getLeaseInfoById(e) {
      getLeaseInfoById(e).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.model = {
            leaseTitle: res.returnObject.leaseTitle,
            parkingId: res.returnObject.parkingLotId,
            parkingName: res.returnObject.parkingName,
            longLeasePeriod: res.returnObject.longLeasePeriod.toString(),
            parkingLotInherit: "1",
            limitTime: "0",
            effectualTime: res.returnObject.effectualTime.toString(),
            beginTime: res.returnObject.beginTime,
            endTime: res.returnObject.endTime,
            buyWay: res.returnObject.buyWay == undefined ? "" : res.returnObject.buyWay.toString(),
            onlineShow: res.returnObject.onlineShow == undefined ? "" : res.returnObject.onlineShow.toString(),
            licensePlateColor: JSON.parse(res.returnObject.licensePlateColor),
            leasePrice: res.returnObject.leasePrice,
            leaseRemark: res.returnObject.leaseRemark,
          };
        }
      });
    },
  },
  mounted() {
    const id = this.$route.query.id;
    //判断是否是来自编辑页面，禁止修改某些选项
    if (id === undefined) {
      this.id = "";
      this.editDisabledInput = false;
    } else {
      this.id = id;
      this.editDisabledInput = true;
      //查询当前长租套餐的信息
      this.getLeaseInfoById(id);
    }
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;
  // background-color: #F5F7FA;
  height: 30px;
  margin: -18px -18px 18px -18px;

  .back-button {
    position: absolute;
    right: 20px;
    top: -4px;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
